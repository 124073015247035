import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "nav-btn",
    onClick: _cache[0] || (_cache[0] = () => $props.onClick()),
    style: _normalizeStyle({
    backgroundColor: $props.active ? 'var(--token-semantic-color-accent-subtle)' : 'transparent',
    color: $props.active ? 'var(--token-semantic-color-accent-default)' : 'inherited',
  })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(`bi ${$props.icon}`)
    }, null, 2 /* CLASS */),
    _createElementVNode("h3", null, _toDisplayString($props.text), 1 /* TEXT */)
  ], 4 /* STYLE */))
}