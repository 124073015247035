import WebScrappingInfra from "@/infra/crawler_api/web_scrapping_queue/web_scrapping_queue_infra";
import QueueItemModel from "@/models/queue_aggregate/queue_item_model";
import StoresInfoModel from "@/models/queue_aggregate/stores_info_model";
import StoreModel from "@/models/store_model";
import { defineStore } from "pinia";
import * as XLSX from "xlsx";

interface ICrawlerState {
  availableStores: StoreModel[],
  availableTags: string[],
  queueItems: QueueItemModel[],
  currentPage: number,
  pageSize: number,
  files: FileList | null,
  selectedStores: { [key: string]: boolean } | null,
  selectedTags: { [key: string]: boolean } | null,
  searchHumanName: boolean,
  realtimeStoresInfo: { [key: string]: StoresInfoModel },
  pageCount: number,
}

export const useCrawlerStore = defineStore('crawler',{
  state: (): ICrawlerState => ({
    availableStores: new Array<StoreModel>(),
    availableTags: new Array<string>(),
    queueItems: new Array<QueueItemModel>(),
    currentPage: 1,
    pageSize: 10,
    files: null,
    selectedStores: null,
    selectedTags: null,
    searchHumanName: true,
    realtimeStoresInfo: {},
    pageCount: 1,
  }),
  actions: {
    async getAvailableStores() {
      this.availableStores = await WebScrappingInfra.getStores();
      this.selectedStores = {};
      for (const store of this.availableStores) {
        this.selectedStores[store.name] = true;
      }
    },

    async getAvailableTags() {
      if (!this.availableStores.length) {
        this.availableStores = await WebScrappingInfra.getStores();
      }

      this.selectedTags = {};

      this.availableStores.forEach(store => {
        store.tags.forEach(tag => {
          if (tag !== "USES_EAN" && tag !== "USES_API") {
            if (!this.availableTags.includes(tag)) this.availableTags.push(tag);
            this.selectedTags![tag] = true;
          }
        });
      });
    },

    async getQueueItems() {
      const res = await WebScrappingInfra.getQueueItems({
        page: this.getCurrentPage() ?? 1,
        limit: this.getPageSize() ?? 10,
      });

      this.queueItems = res.list;
      this.pageCount = res.pageCount;
    },
     
    replaceNameWithLabel(storesToRun: string[] | null | undefined) {
      if (!this.availableStores) return [];
      return storesToRun?.map(storeName => {
        const storeObj = this.availableStores.find(store => store.getName() === storeName);
        return storeObj ? storeObj.label : storeName;
      }
      ) || [];
    },

    async startExecution() {
      if (this.files == null) return;
      if (this.files.length <= 0) return;

      const blobs: { [key: string]: Blob } = {};
      for (const file of this.files) {
        const blob = new Blob([file]);
        blobs[file.name] = blob;
      }

      const selectedStores = this.selectedStores as { [key: string]: boolean, };
      const storesToRun = Object.keys(selectedStores).filter((key) => selectedStores[key]);
      const selectedTags = this.selectedTags as { [key: string]: boolean };
      const tagsToRun = Object.keys(selectedTags).filter((key) => this.selectedTags![key]);

      await WebScrappingInfra.enqueue({
        process_info: {
          input_connector_id: "SHEET_INPUT",
          output_connector_id: "STUDY_OUTPUT",
          ling_comp_connector_id: "FUZZY",
          input_params: {
            sheet_title: this.files[0].name,
            search_human_name: this.searchHumanName,
          },
          stores_to_run: storesToRun,
          tags_to_run: tagsToRun,
          title: this.files[0].name,
        },
        files: blobs,
      });
    },
    getStoreSelection(storeName: string): boolean {
      if (this.selectedStores == null) return false;
      return this.selectedStores[storeName];
    },

    setStoreSelection(storeName: string, value: boolean) {
      if (!this.availableStores || !this.selectedTags || !this.selectedStores) return;

      const store = this.availableStores.find(store => store.name === storeName);
      if (!store) return;

      const tagsToUpdate = store.tags.filter(tag => tag !== 'USES_EAN' && tag !== 'USES_API');

      tagsToUpdate.forEach(tag => {

        const shouldUpdate = value
          ? !this.selectedTags![tag]
          : !this.availableStores.some(otherStore => otherStore.name !== storeName && otherStore.tags.includes(tag) && this.selectedStores?.[otherStore.name]);

        if (shouldUpdate) {
          this.selectedTags![tag] = value;
        }
      });

      this.selectedStores[storeName] = value;
    },

    getTagSelection(tagName: string): boolean {
      if (this.selectedTags == null) return false;
      return this.selectedTags[tagName];
    },

    setTagSelection(tagName: string, value: boolean) {
      if (this.selectedTags == null || this.selectedStores == null) return;
      if (this.selectedTags[tagName] === value) return;

      this.selectedTags[tagName] = value;
      for (const store of this.availableStores) {
        if (store.tags.includes(tagName)) {
          this.selectedStores[store.name] = value;
        }
      }
    },

    getSearchHumanName() {
      return this.searchHumanName;
    },

    setSearchHumanName(value: boolean) {
      this.searchHumanName = value;
    },

    getPageSize() {
      return this.pageSize;
    },

    getPageCount() {
      return this.pageCount;
    },

    getCurrentPage() {
      return this.currentPage;
    },

    async setCurrentPage(page: number) {
      this.currentPage = page;
      await this.getQueueItems();
    },

    downloadSampleSheet() {
      const info = [
        {
          "EAN": "7894900010015",
          "Nome": "REFRIGERANTE COCA-COLA LATA 350ML",
          "Marca": "COCA-COLA",
          "Margem Esperada": '',
          "Custo do fornecedor": 3.16,
          "Quantidade Total da Oferta": 5000,
          "Validade Lote": "31/10/2077",
        }
      ];
      const worksheet = XLSX.utils.json_to_sheet(info);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Dados');
      XLSX.writeFile(workbook, `${'Planilha Exemplo.xlsx'}`, {
        bookType: 'xlsx',
        type: 'file',
      });
    },
  },
})