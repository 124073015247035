import ClassificationModel from "@/models/classification_model";
import CrawlerApiRequester from "../crawler_api_requester";
import { JsonSerializer } from "typescript-json-serializer";
import VoteModel from "@/models/vote_model";
import Constants from "@/infra/constants/constants";
import saveAs from "file-saver";

class ClassificationsInfra {

  static controller = 'classifications';

  static async getClassifications(amount: number): Promise<ClassificationModel[]> {
    try {
      const axios = CrawlerApiRequester.getAxios();
      const res = await axios.get(`${Constants.getCrawlerAPIEndpoint()}/${ClassificationsInfra.controller}?amount=${amount}`);
      const jsonSerializer = new JsonSerializer();
      const classifications = jsonSerializer.deserializeObjectArray(res.data, ClassificationModel) as ClassificationModel[];
      return classifications;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  static async createClassifications(createClassifications: {
    productName1: string,
    productName2: string,
    crawlerEval: boolean,
    evaluation: boolean,
  }[]): Promise<{
    classifications: ClassificationModel[],
    votes: VoteModel[]
  }> {
    try {
      const axios = CrawlerApiRequester.getAxios();
      const res = await axios.post(`${Constants.getCrawlerAPIEndpoint()}/${ClassificationsInfra.controller}/batch`, createClassifications);
      const jsonSerializer = new JsonSerializer();
      const classifications = jsonSerializer.deserializeObjectArray(res.data.classifications, ClassificationModel) as ClassificationModel[];
      const votes = jsonSerializer.deserializeObjectArray(res.data.votes, VoteModel) as VoteModel[];
      return {
        classifications,
        votes,
      };
    } catch (e) {
      console.log(e);
      return {
        classifications: [],
        votes: [],
      };
    }
  }

  static async voteClassifications(createVotes: {
    classificationId: string,
    evaluation: boolean | null,
  }[]): Promise<VoteModel[]> {
    try {
      const filteredVotes = createVotes.filter((vote) => vote.evaluation !== null);
      const axios = CrawlerApiRequester.getAxios();
      const res = await axios.post(`${Constants.getCrawlerAPIEndpoint()}/${ClassificationsInfra.controller}/vote/batch`, filteredVotes);
      const jsonSerializer = new JsonSerializer();
      const votes = jsonSerializer.deserializeObjectArray(res.data, VoteModel) as VoteModel[];
      return votes;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  static async downloadDataset(): Promise<boolean> {
    try {
      const axios = CrawlerApiRequester.getAxios();
      const res = await axios.get(`${Constants.getCrawlerAPIEndpoint()}/${ClassificationsInfra.controller}/dataset-download`, {
        responseType: "blob",
      });
      saveAs(res.data, "linguistic_comparison_dataset.xlsx");
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
}

export default ClassificationsInfra;