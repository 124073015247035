import DarkMode from "@/infra/dark_mode/dark_mode";
import Theme from "@/infra/dark_mode/theme_enum";
import { defineStore } from "pinia";

export const useThemeStore = defineStore('theme',{
  state: () => ({
    currentTheme: DarkMode.theme,
  }),
  actions: {
    toggleTheme() {
      this.setTheme(this.currentTheme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT);
    },
    setTheme(theme: Theme) {
      DarkMode.setTheme(theme);
      this.currentTheme = theme;
    },
  }
})