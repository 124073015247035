import { JsonObject, JsonProperty } from "typescript-json-serializer";
import QueueStatus from "./queue_status";

@JsonObject()
class QueueItemModel {

  @JsonProperty()
  private id: string;

  @JsonProperty()
  private title: string;

  @JsonProperty("stores_to_run")
  private storesToRun: string[] | null;

  @JsonProperty("tags_to_run")
  private tagsToRun: string[] | null;

  @JsonProperty()
  private status: QueueStatus;

  @JsonProperty("submission_date_utc")
  private submissionDateUtc: number;

  @JsonProperty("start_date_utc")
  private startDateUtc: number;

  @JsonProperty("end_date_utc")
  private endDateUtc: number;

  @JsonProperty()
  private instance: string;

  @JsonProperty("input_connector_id")
  private inputConnectorId: string;

  @JsonProperty("input_params")
  private inputParams: string;

  @JsonProperty("output_connector_id")
  private outputConnectorId: string;

  @JsonProperty("output_data")
  private outputData: string;

  @JsonProperty("ling_comp_connector_id")
  private lingCompConnectorId: string;

  @JsonProperty("error_message")
  private errorMessage: string;

  @JsonProperty("created_by_user_name")
  private createdByUserName: string;

  constructor(params: {
    id: string,
    title: string,
    storesToRun: string[],
    tagsToRun: string[],
    status: number,
    submissionDateUtc: number,
    startDateUtc: number,
    endDateUtc: number,
    instance: string,
    inputConnectorId: string,
    inputParams: string,
    outputConnectorId: string,
    outputData: string,
    lingCompConnectorId: string,
    errorMessage: string,
    createdByUserName: string,
  }) {
    this.id = params.id;
    this.title = params.title;
    this.storesToRun = params.storesToRun;
    this.tagsToRun = params.tagsToRun;
    this.status = params.status;
    this.submissionDateUtc = params.submissionDateUtc;
    this.startDateUtc = params.startDateUtc;
    this.endDateUtc = params.endDateUtc;
    this.instance = params.instance;
    this.inputConnectorId = params.inputConnectorId;
    this.inputParams = params.inputParams;
    this.outputConnectorId = params.outputConnectorId;
    this.outputData = params.outputData;
    this.lingCompConnectorId = params.lingCompConnectorId;
    this.errorMessage = params.errorMessage;
    this.createdByUserName = params.createdByUserName;
  }

  public getId() {
    return this.id;
  }

  public getTitle() {
    return this.title;
  }

  public getStoresToRun() {
    return this.storesToRun;
  }

  public getTagsToRun() {
    if (this.tagsToRun?.length === 0) return null;
    return this.tagsToRun;
  }

  public getStatus() {
    return this.status;
  }

  public setStatus(status: QueueStatus) {
    this.status = status;
  }

  public getSubmissionDate() {
    return new Date(this.submissionDateUtc);
  }

  public getStartDate() {
    return new Date(this.startDateUtc);
  }

  public getEndDate(): Date | null {
    if (!this.endDateUtc) return null;
    return new Date(this.endDateUtc);
  }

  public hasEnded() {
    return this.status === QueueStatus.done || this.status === QueueStatus.error || this.status === QueueStatus.cancelled;
  }

  public getDurationInMinutes(): string {
    const endDate = this.endDateUtc ?? new Date(Date.now());
    const duration = {
      days: Math.floor((endDate - this.startDateUtc) / 1000 / 60 / 60 / 24),
      hours: Math.floor((endDate - this.startDateUtc) / 1000 / 60 / 60 % 24),
      minutes: Math.floor((endDate - this.startDateUtc) / 1000 / 60 % 60),
      seconds: Math.floor((endDate - this.startDateUtc) / 1000 % 60),
    };
    let info = "";
    if (duration.days > 0) info += `${duration.days}d `;
    if (duration.hours > 0) info += `${duration.hours}h `;
    if (duration.minutes > 0) info += `${duration.minutes}m `;
    if (duration.seconds > 0) info += `${duration.seconds}s`;
    return info;
  }
  
  public getMessage() {
    let info = "";
    switch (this.status) {
      case QueueStatus.done:
        info = "Concluído";
        break;
      case QueueStatus.error:
        info = "Erro";
        break;
      case QueueStatus.cancelled:
        info = "Cancelado";
        break;
      case QueueStatus.running:
        return "Em andamento";
      case QueueStatus.waiting:
        return "Aguardando na fila";
    }
    return `${info} em ${this.getEndDate()?.toLocaleString()}`;
  }

  public getErrorMessage() {
    return this.errorMessage;
  }

  public getInputParams() {
    return JSON.parse(this.inputParams);
  }

  public getOutputData() {
    return JSON.parse(this.outputData);
  }

  public setOutputData(outputData: any) {
    this.outputData = JSON.stringify(outputData);
  }

  public getInstance() {  
    return this.instance;
  }

  public getInputConnectorId() {
    return this.inputConnectorId;
  }

  public getOutputConnectorId() {
    return this.outputConnectorId;
  }

  public getLingCompConnectorId() {
    return this.lingCompConnectorId;
  }

  public getCreatedByUserName() {
    return this.createdByUserName;
  }

}

export default QueueItemModel;