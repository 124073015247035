import UserModel from "@/models/user_aggregate/user_model";
import CrawlerApiRequester from "../crawler_api_requester";
import { JsonSerializer } from 'typescript-json-serializer';
import Constants from "@/infra/constants/constants";
import { PaginatedListDTO } from "@/models/paginated_list_model";

class CrawlerUserInfra {

  static controller = 'crawler-users';

  static async singIn(): Promise<UserModel | null> {

    try {
      const axios = CrawlerApiRequester.getAxios();
      const res = await axios.post(`${Constants.getCrawlerAPIEndpoint()}/${CrawlerUserInfra.controller}/signin`);
      const jsonSerializer = new JsonSerializer();
      const user = jsonSerializer.deserializeObject(res.data, UserModel);
      if (!user) return null;
      return user;
    } catch (e) {
      console.log(e)
      return null;
    }
  }  

  static async getUserRankingList(params: {
    pageNumber: number,
    pageSize: number,
    startDate?: Date,
    endDate?: Date,
  }): Promise<{ user: UserModel | null, paginatedList: PaginatedListDTO<UserModel> }> {
    try {
      const axios = CrawlerApiRequester.getAxios();
      let path = `${Constants.getCrawlerAPIEndpoint()}/${CrawlerUserInfra.controller}/ranking/paginate?currentPage=${params.pageNumber}&pageSize=${params.pageSize}`;
      if (params.startDate && params.endDate) path += `&startDate=${params.startDate?.toISOString()}&endDate=${params.endDate?.toISOString()}`;
      const res = await axios.get(path);
      const jsonSerializer = new JsonSerializer();
      const userList = jsonSerializer.deserializeObjectArray(res.data.paginatedList.list, UserModel) as UserModel[];
      
      const userInfo = jsonSerializer.deserializeObject(res.data.user, UserModel) as UserModel;

      return {
        user: userInfo,
        paginatedList: new PaginatedListDTO({
          list: userList,
          pageCount: res.data.paginatedList.pageCount,
        })
      };
    } catch (e) {
      console.log(e);
      return { user: null, paginatedList: PaginatedListDTO.empty<UserModel>() };
    }
  }
}

export default CrawlerUserInfra;