import moment from "moment";

class FormatUtils {
  static money(value: number | null | undefined, addBRL=false): string {
    if (Number.isNaN(value) || value === Infinity || value === -Infinity) value = null;
    return `${addBRL ? 'R$ ' : ''}${value?.toFixed(2).replaceAll('.', ',') ?? '-'}`
  }

  static ean(value: string | null | undefined): string {
    if (!value) return "-";
    return value.padStart(13, '0');
  }

  static ellipsis(value: string | null | undefined, size: number): string {
    if (!value) return "-";
    if (size > value.length) return value;
    return `${value.slice(0, size)}...`;
  }

  static commonNumber(value: number | string | null | undefined, fixed: number|null=null): string {
    if (value === null || value === undefined) return "-";
    if (fixed !== null && typeof value === 'number') value = value.toFixed(fixed);
    if (typeof value === "number" && isNaN(value)) return '-';
    return value.toString().replaceAll('.', ',');
  }

  static date(date: Date | null | undefined | number) {
    if (typeof(date) === 'number') date = new Date(date);
    if (!date) return '-';
    return moment(date).format('DD/MM/YYYY');
  }
}

export default FormatUtils;