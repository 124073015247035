import axios, { AxiosInstance } from 'axios';
import FirebaseUtils from '../firebase/firebase_utils';

class CrawlerApiRequester {

  private static axios: AxiosInstance | null = null;

  public static getAxios(): AxiosInstance {
    if (CrawlerApiRequester.axios) return CrawlerApiRequester.axios;
    CrawlerApiRequester.axios = axios.create();
    CrawlerApiRequester.axios.interceptors.request.use(async (config) => {
      
      const firebaseUser = await FirebaseUtils.getCurrentUser();
      const token = await firebaseUser?.getIdToken();
      if (token) CrawlerApiRequester.setAxiosToken(token);
      return config;
    });
    return CrawlerApiRequester.axios;
  }

  public static setAxiosToken(token: string) {
    CrawlerApiRequester.getAxios().defaults.headers.Authorization = `Bearer ${token}`;
  }

  public static clearAxiosToken() {
    delete CrawlerApiRequester.getAxios().defaults.headers.Authorization;
  }
}

export default CrawlerApiRequester;