// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/images/crawler_login_image.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\nmain[data-v-5c6101e4] {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  min-height: 100vh;\r\n  padding: 8px;\r\n  background-color: var(--token-semantic-color-bg-surface);\n}\n.crawler-image[data-v-5c6101e4] {\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n  background-size: cover;\r\n  height: 96vh;\r\n  width: 101vh;\r\n\r\n  max-width: 90vw;\r\n  max-height: 90vw;\r\n\r\n  border-radius: calc(var(--token-semantic-border-radius-enormous) * 1px);\r\n  padding: 32px;\n}\r\n\r\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
