<script setup lang="ts">

</script>

<template>
  <div class="classification-info">
    <h2 class="bold-medium text-center">Lembre-se, só são o mesmo produto se tiverem:</h2>
    <div class="remember-item">
      <div class="bi bi-check-circle"></div>
      <p class="regular-default">Mesma marca</p>
    </div>
    
    <div class="remember-item">
      <div class="bi bi-check-circle"></div>
      <p class="regular-default">Mesmo tipo e sabor</p>
    </div>
    
    <div class="remember-item">
      <div class="bi bi-check-circle"></div>
      <p class="regular-default">Mesma quantidade e pesagem/litragem</p>
    </div>
  </div>
</template>

<style scoped>

.classification-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: calc(var(--token-semantic-spacing-stack-100) * 1px);
  width: 100%;
  padding: 32px;
  background-color: var(--token-semantic-color-bg-subtle);
  border-radius: calc(var(--token-semantic-border-radius-large) * 1px);
  color: var(--token-semantic-color-fg-muted);
  box-shadow: 0px 0px 12.5812px rgba(0, 0, 0, 0.05);
}

.remember-item {
  display: flex;
  gap: calc(var(--token-semantic-spacing-inline-075) * 1px);
  align-items: center;
  color: var(--token-semantic-color-fg-default);
}

.remember-item > .bi {
  color: var(--token-semantic-color-success-default);
  font-size: var(--token-semantic-size-icons-250);
}

</style>