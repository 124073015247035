import ReportsInfra from "@/infra/crawler_api/reports/reports_infra";
import ReportModel from "@/models/report_model";
import { defineStore } from "pinia";

interface IReportsState {
  reports: ReportModel[],
  currentPage: number,
  pageSize: number,
  pageCount: number,
  filter: string | null,
}

export const useReportsStore = defineStore('reports',{
  state: (): IReportsState => ({
    reports: new Array<ReportModel>(),
    currentPage: 1,
    pageSize: 10,
    pageCount: 1,
    filter: null,
  }),
  actions: {
    async getReports() {
      const res = await ReportsInfra.getReports({
        pageNumber: this.getCurrentPage() ?? 1,
        pageSize: this.getPageSize() ?? 10,
        filter: this.getFilter() ?? ''
      });

      this.reports = res.list
      this.pageCount = res.pageCount
    },

    getPageSize() {
      return this.pageSize;
    },

    getPageCount() {
      return this.pageCount;
    },

    getCurrentPage() {
      return this.currentPage;
    },

    async setCurrentPage({page}: { page: number }) {
      this.currentPage = page;
      await this.getReports();
    },

    getFilter() {
      return this.filter;
    },

    async setFilter({filter}:{filter: string}) {
      this.filter = filter;
      await this.getReports();
    }
  }
})