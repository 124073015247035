import { RouteLocationNormalized } from "vue-router";
import { routes } from '@/router'
import UserStorage from "@/infra/user_storage/user_storage";
import FirebaseUtils from "@/infra/firebase/firebase_utils";
import CrawlerApiRequester from "@/infra/crawler_api/crawler_api_requester";

async function loginGuard(to: RouteLocationNormalized): Promise<boolean>{
  const user = UserStorage.getUser();
  const firebaseUser = await FirebaseUtils.getCurrentUser();  
  
  const token = await firebaseUser?.getIdToken();
  if (token) CrawlerApiRequester.setAxiosToken(token);

  const hasExpired = await FirebaseUtils.hasTokenExpired();
  
  const isLogged = user != null && firebaseUser != null && !hasExpired;
  return to.name === routes.login.name || isLogged;
}

export default loginGuard;