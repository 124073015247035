import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ccce1c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input" }
const _hoisted_2 = ["disabled", "type", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("input", {
        onMouseover: _cache[0] || (_cache[0] = ($event: any) => ($setup.isHovered = true)),
        onMouseleave: _cache[1] || (_cache[1] = ($event: any) => ($setup.isHovered = false)),
        disabled: $props.state == 'disabled',
        ref: "inputField",
        class: "common-input",
        type: $props.type,
        onFocus: _cache[2] || (_cache[2] = ($event: any) => ($setup.isFocused = true)),
        onBlur: _cache[3] || (_cache[3] = ($event: any) => ($setup.isFocused = false)),
        value: $props.modelValue,
        style: _normalizeStyle(`border-color: var(--token-input${$setup.getState()}-border-color)`),
        onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update:model-value', ($event.target as HTMLInputElement).value))),
        onFocusout: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('leave-focus', ($event.target as HTMLInputElement).value))),
        onPaste: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('on-paste', ($event)))),
        onKeydown: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('key-down', ($event))))
      }, null, 44 /* STYLE, PROPS, HYDRATE_EVENTS */, _hoisted_2),
      _createElementVNode("div", {
        class: _normalizeClass(['semantic-typography-body-regular-default', 'input-field-label', 
        {'input-field-label-focused': $setup.floatOn, 'semantic-typography-body-bold-small' : $setup.floatOn}]),
        style: _normalizeStyle(`color: var(--token-input${$setup.getState()}-label-color)`)
      }, _toDisplayString($props.label), 7 /* TEXT, CLASS, STYLE */),
      ($props.modelValue.length)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            onMouseover: _cache[9] || (_cache[9] = ($event: any) => ($setup.isHovered = true))
          }, [
            _createElementVNode("i", {
              class: "bi bi-x-circle input-field-icon",
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('tap-trailing', $event))),
              style: _normalizeStyle(`color: var(--token-input${$setup.getState()}-icon-color)`)
            }, null, 4 /* STYLE */)
          ], 32 /* HYDRATE_EVENTS */))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("p", {
      class: "mt-2 semantic-typography-body-regular-small",
      style: _normalizeStyle(`color: var(--token-input${$setup.getState()}-supporting-text-color)`)
    }, _toDisplayString($props.supportingText), 5 /* TEXT, STYLE */)
  ]))
}