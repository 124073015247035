import { JsonObject, JsonProperty } from "typescript-json-serializer";

@JsonObject()
class ClassificationModel {

  @JsonProperty()
  private id: string;
  @JsonProperty()
  private productName1: string;
  @JsonProperty()
  private productName2: string;
  @JsonProperty()
  private crawlerEval: boolean;
  @JsonProperty()
  private createdAtUtc: Date;

  constructor(params: {
    id: string,
    productName1: string,
    productName2: string,
    crawlerEval: boolean,
    createdAtUtc: Date,
  }) {
    this.id = params.id;
    this.productName1 = params.productName1;
    this.productName2 = params.productName2;
    this.crawlerEval = params.crawlerEval;
    this.createdAtUtc = params.createdAtUtc;
  }

  getId(): string {
    return this.id;
  }

  getProductName1(): string {
    return this.productName1;
  }

  getProductName2(): string {
    return this.productName2;
  }

  getCrawlerEval(): boolean {
    return this.crawlerEval;
  }

  getCreatedAtUtc(): Date {
    return this.createdAtUtc;
  }
}

export default ClassificationModel;