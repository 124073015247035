import CrawlerApiRequester from "../crawler_api_requester";
import { JsonSerializer } from 'typescript-json-serializer';
import Constants from "@/infra/constants/constants";
import ReportModel from "@/models/report_model";
import { PaginatedListDTO } from "@/models/paginated_list_model";

class ReportInfra {

  static controller = 'embedded-reports';

  static async createReport(params: {
    title: string,
    description: string,
    tags: string[],
    url: string,
    width: number,
    height: number,
  }): Promise<ReportModel | null> {
    try {
      const axios = CrawlerApiRequester.getAxios();
      const res = await axios.post(`${Constants.getCrawlerAPIEndpoint()}/${ReportInfra.controller}/`, params);
      const jsonSerializer = new JsonSerializer();
      return jsonSerializer.deserializeObject(res.data, ReportModel) as ReportModel;
    } catch(e) {
      console.log(e);
      return null;
    }
  }

  static async updateReport(id: string, params: {
    title: string,
    description: string,
    tags: string[],
    url: string,
    width: number,
    height: number,
  }): Promise<ReportModel | null> {
    try {
      const axios = CrawlerApiRequester.getAxios();
      const res = await axios.patch(`${Constants.getCrawlerAPIEndpoint()}/${ReportInfra.controller}/${id}`, params);
      const jsonSerializer = new JsonSerializer();
      return jsonSerializer.deserializeObject(res.data, ReportModel) as ReportModel;
    } catch(e) {
      console.log(e);
      return null;
    }
  }

  static async getReports(params:{
    pageNumber: number, 
    pageSize: number, 
    filter?: string
  }): Promise<PaginatedListDTO<ReportModel>> {
    try {
      const axios = CrawlerApiRequester.getAxios();
      const url = `${Constants.getCrawlerAPIEndpoint()}/${ReportInfra.controller}/paginate`;
      const queryParams = {
        currentPage: params.pageNumber,
        pageSize: params.pageSize,
      }

      if (params.filter) {
        queryParams['filter'] = params.filter;
      }

      const res = await axios.get(url, { params: queryParams });
      const jsonSerializer = new JsonSerializer();
      const reports = jsonSerializer.deserializeObjectArray(res.data.list, ReportModel) as ReportModel[];
      return new PaginatedListDTO<ReportModel>({
        list: reports, 
        pageCount: res.data.pageCount
      });
    } catch (e) {
      console.log(e);
      return PaginatedListDTO.empty<ReportModel>();
    }
  }

  static async deleteReports(id: string): Promise<boolean> {
    try {
      const axios = CrawlerApiRequester.getAxios();
      await axios.delete(`${Constants.getCrawlerAPIEndpoint()}/${ReportInfra.controller}/${id}`);
      return true;
    } catch(e) {
      console.log(e);
      return false;
    }
  }
}

export default ReportInfra;