import { JsonObject, JsonProperty } from 'typescript-json-serializer';

@JsonObject()
class ReportModel {
  
  @JsonProperty('id')
  private id: string;
  
  @JsonProperty('title')
  private title: string;

  @JsonProperty('description')
  private description: string;

  @JsonProperty('url')
  private url: string;

  @JsonProperty('tags')
  private tags: string[] | null;

  @JsonProperty('width')
  private width: number;

  @JsonProperty('height')
  private height: number;

  @JsonProperty('created_at_utc')
  private createdAtUtc: Date;

  @JsonProperty('updated_at_utc')
  private updatedAtUtc: Date | null;

  constructor(params: {
    id: string;
    title: string;
    description: string;
    url: string;
    tags: string[];
    width: number;
    height: number;
    createdAtUtc: Date;
    updatedAtUtc: Date;
  }) {
    this.id = params.id,
    this.title = params.title;
    this.description = params.description;
    this.url = params.url;
    this.tags = params.tags;
    this.width = params.width;
    this.height = params.height;
    this.createdAtUtc = params.createdAtUtc,
    this.updatedAtUtc = params.updatedAtUtc;
  }

  public getTitle(): string {
    return this.title;
  }

  public getDescription(): string {
    return this.description;
  }

  public getTags(): string[] | null {
    return this.tags;
  }

  public getWidth(): number {
    return this.width;
  }

  public getCreatedAtUtc(): Date {
    return this.createdAtUtc;
  }

  public getUpdatedAtUtc(): Date | null {
    return this.updatedAtUtc;
  }

  public getId(): string {
    return this.id;
  }

  public getUrl(): string {
    return this.url;
  }
  
  public getHeight(): number {
    return this.height;
  }
}

export default ReportModel;