interface IStudyData {
  globalExpectedMargin: number | null,
  lastOpened: number,
  alteredIn: number,

  products: IProductStudyData[],
}

interface IProductStudyData {
  headerData: {
    sku: string | null,
    ean: string | null,
    name: string | null,
    price: number | null,
    priceCost: number | null,
    totalStockQuantity: number | null,
    lastEntranceTotalValue: number | null,
    lastEntranceQuantity: number | null,
    lastEntranceDate: number | null,
  },
  inputsData: {
    expectedMargin: number | null,
    supplierPrice: number,
    totalBatchSize: number,
    batchExpiresAt: number,
  },
  b2cData: {
    amountSoldLast30Days: number | null,
    averagePriceLast30Days: number | null,
  },
  b2bData: {
    lastQuantitySold: number | null,
    lastPriceSold: number | null,
    lastSoldDate: number | null,
  },
  prices: {
    productName: string,
    storeName: string,
    price: number,
  }[],
  differentProducts: {
    storeName: string,
    productName: string,
  }[],
}

class StudyMethods {
  static getProduct(study: IStudyData, index: number) {
    return study.products[index];
  }

  static calculateTotalPrice(study: IStudyData) {
    const products = study.products;
    if (!products) return 0;
    return products.reduce((a, v) => {
      if (!v.inputsData?.supplierPrice || !v.inputsData?.totalBatchSize) return a;
      return a + v.inputsData.supplierPrice * v.inputsData.totalBatchSize;
    }, 0) ?? 0;
  }

  static getProductExpectedMargin(study: IStudyData, index: number) {
    const product = this.getProduct(study, index);
    return product.inputsData?.expectedMargin ?? study.globalExpectedMargin ?? 25
  }

  static getAppIdealPrice(study: IStudyData, index: number) {
    const product = this.getProduct(study, index);
    const expectedMargin = this.getProductExpectedMargin(study, index);
    const supplierPrice = product.inputsData.supplierPrice;
    if (!supplierPrice) return null;
    return - (supplierPrice / ((expectedMargin / 100) - 1));
  }

  static getStatus(study: IStudyData, index: number) {
    const product = this.getProduct(study, index);
    const expectedMargin = this.getProductExpectedMargin(study, index);
    if (!product.b2cData?.averagePriceLast30Days) return "SEM DADOS";
    if (!product.inputsData?.supplierPrice) return "SEM DADOS";
    const status = product.inputsData.supplierPrice <= product.b2cData?.averagePriceLast30Days * (1 - (expectedMargin / 100));
    return status ? "APROVADO" : "NEGOCIAR";
  }

  static getDaysTillExpires(study: IStudyData, index: number) {
    const product = this.getProduct(study, index);
    if (!product.inputsData?.batchExpiresAt) return null;
    const millis = (product.inputsData?.batchExpiresAt * 1000);
    return Math.floor((millis - Date.now()) / (1000 * 60 * 60 * 24));
  }
  
  static getTotalBatchPrice(study: IStudyData, index: number) {
    const product = this.getProduct(study, index);
    const appIdealPrice = this.getAppIdealPrice(study, index);
    if (!product.inputsData?.totalBatchSize) return null;
    if (!appIdealPrice) return null;
    return product.inputsData?.totalBatchSize * appIdealPrice;    
  }

  static getProposedBatchPrice(study: IStudyData, index: number) {
    const product = this.getProduct(study, index);
    if (!product.inputsData?.totalBatchSize) return null;
    if (!product.inputsData?.supplierPrice) return null;
    return product.inputsData.supplierPrice * product.inputsData.totalBatchSize;
  }

  static getLossExpectation(study: IStudyData, index: number) {
    const product = this.getProduct(study, index);
    const daysTillExpires = StudyMethods.getDaysTillExpires(study, index);
    if (!product.inputsData?.totalBatchSize) return null;
    if (!product.b2cData?.amountSoldLast30Days) return null;
    if (!daysTillExpires) return null;
    return (product.headerData?.totalStockQuantity??0) + product.inputsData?.totalBatchSize - ((product.b2cData?.amountSoldLast30Days / 30) * daysTillExpires)
  }

  static getSuggestedPurchasePrice(study: IStudyData, index: number) {
    const product = this.getProduct(study, index);
    const expectedMargin = this.getProductExpectedMargin(study, index) / 100;
    if (!product.b2cData?.averagePriceLast30Days) return null;
    return product.b2cData!.averagePriceLast30Days * (1 - expectedMargin);
  }

  static getCurrentMargin(study: IStudyData, index: number) {
    const product = this.getProduct(study, index);
    if (!product.headerData?.price) return null;
    if (!product.headerData?.priceCost) return null;
    return ((product.headerData?.price - product.headerData?.priceCost) / product.headerData?.price) * 100;
  }

  static getProductPercent(study: IStudyData, index: number) {
    const totalPrice = this.calculateTotalPrice(study);
    const price = this.getProposedBatchPrice(study, index);
    if (!price) return null;
    return price / totalPrice;
  }

}

export { IStudyData, IProductStudyData, StudyMethods };