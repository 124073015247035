import CrawlerUserInfra from "@/infra/crawler_api/crawler_user/crawler_user_infra";
import UserStorage from "@/infra/user_storage/user_storage";
import RankingType from "@/models/ranking_type_enum";
import UserModel from "@/models/user_aggregate/user_model";
import { defineStore } from "pinia";

export const useRankingStore = defineStore('ranking',{
  state: () => ({
    users: new Array<UserModel>(),
    pageNumber: 1,
    pageSize: 10,
    pageCount: 1,
    filterMode: RankingType.MONTHLY,
  }),
  actions: {
    async getRanking() {
      const date = new Date();
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      const { user, paginatedList } = await CrawlerUserInfra.getUserRankingList({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        startDate: this.filterMode === RankingType.MONTHLY ? firstDay : undefined,
        endDate: this.filterMode === RankingType.MONTHLY ? lastDay : undefined,
      });
      
      this.users = paginatedList.list;
      this.pageCount = paginatedList.pageCount;
      
      if (!this.users.some(user => user.getId() === UserStorage.getUser()?.getId()) && user) {
        if (user.getRankingPos() < this.users[0].getRankingPos()) {
          this.users.unshift(user);
        } else {
          this.users.push(user);
        }
      }
    },
    async setFilterMode(value: RankingType) {
      this.filterMode = value;
      this.pageNumber = 1;
      await this.getRanking();
    },
    getUserId(): string | undefined {
      return UserStorage.getUser()?.getId();
    },

    getPageSize(): number  {
      return this.pageSize;
    },

    setPageSize(v: number) {
      this.pageSize = v;
    },

    getPageCount(): number  {
      return this.pageCount;
    },

    getPageNumber(): number {
      return this.pageNumber;
    },

    async setPageNumber(page: number) {
      this.pageNumber = page;
      await this.getRanking();
    }
  }
})