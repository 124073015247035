import ClassificationsInfra from '@/infra/crawler_api/classifications/classifications_infra';
import ClassificationModel from '@/models/classification_model';
import EvaluationModel from '@/models/evaluation_model';
import { defineStore } from 'pinia';

export const useClassificationStore = defineStore('classification', {
  state: () => ({
    startScreen: true,
    points: 0,
    notEvaluated: new Array<ClassificationModel>(),
    evaluated: new Array<EvaluationModel>(),
    expandAnswersReview: false,
  }),
  actions: {
    async getClassifications(amount: number): Promise<ClassificationModel[]> {
      this.notEvaluated = await ClassificationsInfra.getClassifications(amount);
      this.setShowAnswersReview(false);
      if (this.notEvaluated.length > 0) {
        this.startScreen = false;
      }
      return this.notEvaluated as ClassificationModel[];
    },
    nextClassification(evaluation: boolean | null) {
      if (this.notEvaluated.length === 0) return;
      const classification = this.notEvaluated.shift()! as ClassificationModel;
      const evaluationModel = new EvaluationModel({
        classification,
        evaluation,
      });
      this.evaluated.push(evaluationModel);
      this.setShowAnswersReview(this.notEvaluated.length === 0);
    },
    async submit(): Promise<number | null> {
      if (this.evaluated.length <= 0) return null;
      
      if (!this.expandAnswersReview) {
        this.setShowAnswersReview(true);
        return null;
      } else {
        const data = this.evaluated.map((evaluation) => ({
          classificationId: evaluation.getClassification().getId(),
          evaluation: evaluation.getEvaluation(),
        }));
        const res = await ClassificationsInfra.voteClassifications(data);
        this.points += res.length;
        return res.length;
      }
    },
    reset() {
      this.startScreen = true;
      this.notEvaluated = [];
      this.evaluated = [];
      this.setShowAnswersReview(false);
    },
    setShowAnswersReview(value: boolean) {
      this.expandAnswersReview = value;
    },
    jump() {
      this.nextClassification(null);
    },
    back() {
      if (this.evaluated.length === 0) return;
      const evaluation = this.evaluated.pop()! as EvaluationModel;
      this.notEvaluated.unshift(evaluation.getClassification());
    },
    edit(evaluation: EvaluationModel) {
      console.log("Edit");
      this.evaluated = this.evaluated.filter(
        (evaluationModel) => evaluationModel !== evaluation
      );
      this.expandAnswersReview = false;
      this.notEvaluated.unshift(evaluation.getClassification());
    }
  },
});
