import { JsonObject, JsonProperty } from "typescript-json-serializer";
import { Role as RoleEnum } from "./user_role_enum";

@JsonObject()
class UserModel {

  @JsonProperty()
  private id: string;
  @JsonProperty()
  private createdAtUTC: Date;
  @JsonProperty()
  private firebaseId: string;
  @JsonProperty()
  private role: number;
  @JsonProperty()
  private fullName: string;
  @JsonProperty()
  private points: number;
  @JsonProperty()
  private rankingPos: number;

  constructor(params:{
    id: string,
    createdAtUTC: Date,
    firebaseId: string,
    role: number,
    fullName: string,
    points: number,
    rankingPos: number,
  }) {
    this.id = params.id;
    this.createdAtUTC = params.createdAtUTC,
    this.firebaseId = params.firebaseId,
    this.role = params.role
    this.fullName = params.fullName
    this.points = params.points
    this.rankingPos = params.rankingPos;
  }

  getId(): string {
    return this.id;
  }

  getCreatedAtUTC(): Date {
    return this.createdAtUTC;
  }

  getFirebaseId(): string {
    return this.firebaseId;
  }

  getRole(): RoleEnum {
    return this.role as RoleEnum;
  }

  getFullName(): string {
    return this.fullName;
  }

  getPoints(): number {
    return this.points;
  }

  getRankingPos(): number {
    return this.rankingPos;
  }
}

export default UserModel;