import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-732d8f66"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ranked-user-info" }
const _hoisted_2 = { class: "rank-pos-text" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "ranked-user-father",
    style: _normalizeStyle({
    backgroundColor: $props.active ? 'var(--token-navigation-bar-item-enabled-pill-color)' : 'transparent',
    color: $props.active ? 'var(--token-semantic-color-primary-default)' : 'var(--token-semantic-color-fg-muted)',
  })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString($props.position), 1 /* TEXT */),
      _createElementVNode("img", {
        class: "rank-img",
        src: $props.avatar,
        width: "32",
        height: "32"
      }, null, 8 /* PROPS */, _hoisted_3),
      _createElementVNode("p", null, _toDisplayString($props.name), 1 /* TEXT */)
    ]),
    _createElementVNode("p", null, _toDisplayString($props.points) + " pts", 1 /* TEXT */)
  ], 4 /* STYLE */))
}