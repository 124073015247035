import { JsonObject, JsonProperty } from "typescript-json-serializer";

@JsonObject()
class VoteModel {
  @JsonProperty()
  private userId: string;

  @JsonProperty()
  private classificationId: string;

  @JsonProperty()
  private evaluation: boolean;

  constructor(params: {
    userId: string,
    classificationId: string,
    evaluation: boolean,
  }) {
    this.userId = params.userId;
    this.classificationId = params.classificationId;
    this.evaluation = params.evaluation;
  }

  getUserId(): string {
    return this.userId;
  }

  getClassificationId(): string {
    return this.classificationId;
  }

  getEvaluation(): boolean {
    return this.evaluation;
  }
}

export default VoteModel;