import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fc27a37c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "parent" }
const _hoisted_2 = { class: "regular-small" }
const _hoisted_3 = { class: "bold-large" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("h4", _hoisted_2, _toDisplayString($props.text), 1 /* TEXT */),
      _createElementVNode("h1", _hoisted_3, _toDisplayString($props.value), 1 /* TEXT */)
    ]),
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}