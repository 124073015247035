export class PaginatedListDTO<T> {
  public list: T[];
  public pageCount: number;

  constructor(params: { list: T[]; pageCount: number }) {
    this.list = params.list;
    this.pageCount = params.pageCount;
  }

  static empty<T>(): PaginatedListDTO<T> {
    return new PaginatedListDTO<T>({
      list: [],
      pageCount: 0,
    });
  }
}