import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eb61acac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "price-tooltip" }
const _hoisted_2 = { class: "regular-small" }
const _hoisted_3 = { class: "title-medium" }
const _hoisted_4 = { class: "tooltip-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_2, _toDisplayString($props.title), 1 /* TEXT */),
      _createElementVNode("p", _hoisted_3, _toDisplayString(`${$setup.FormatUtils.money($props.value, true)}`), 1 /* TEXT */)
    ]),
    _createElementVNode("i", {
      class: "report-btn bi bi-megaphone-fill",
      onClick: $setup.report
    }, [
      _createElementVNode("p", _hoisted_4, _toDisplayString($props.tooltip), 1 /* TEXT */)
    ])
  ]))
}