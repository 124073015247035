import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f8d708e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "father" }
const _hoisted_2 = { class: "regular-large text-center" }
const _hoisted_3 = { style: {"font-weight":"bold"} }
const _hoisted_4 = { class: "cards-parent" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  class: "tooltip bold-small text-center"
}
const _hoisted_7 = { class: "d-flex gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["CommonModal"], { ref: "commonModal" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, [
          _createTextVNode("Selecione produtos iguais à "),
          _createElementVNode("b", _hoisted_3, _toDisplayString($setup.product?.headerData.name), 1 /* TEXT */),
          _createTextVNode(" se houver:")
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.updatableData, (selectableItem, i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "selectable-card",
              key: i,
              onClick: ($event: any) => (selectableItem.selected = !selectableItem.selected),
              style: _normalizeStyle({
          backgroundColor: selectableItem.selected ? 'var(--token-semantic-color-secondary-muted)' : 'var(--token-semantic-color-tertiary-muted)'
        })
            }, [
              _createElementVNode("p", null, _toDisplayString(selectableItem.productName), 1 /* TEXT */),
              (selectableItem.storeName?.length > 3)
                ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(selectableItem.storeName), 1 /* TEXT */))
                : _createCommentVNode("v-if", true)
            ], 12 /* STYLE, PROPS */, _hoisted_5))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode($setup["CommonButton"], {
            "on-click": () => $setup.commonModal?.close(),
            action: 'success'
          }, {
            default: _withCtx(() => [
              _createTextVNode("Enviar")
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["on-click"]),
          _createVNode($setup["CommonButton"], {
            "on-click": () => $setup.commonModal?.close(),
            action: 'error'
          }, {
            default: _withCtx(() => [
              _createTextVNode("Cancelar")
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["on-click"])
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */))
}