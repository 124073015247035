import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5785d766"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "comparison-text-father" }
const _hoisted_2 = { class: "comparison-inner-text" }
const _hoisted_3 = { class: "comparison-inner-text" }
const _hoisted_4 = { class: "d-flex gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "comparison-eval",
    style: _normalizeStyle($setup.style)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString($props.evaluation.getClassification().getProductName1()), 1 /* TEXT */),
      _createElementVNode("p", _hoisted_3, _toDisplayString($props.evaluation.getClassification().getProductName2()), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", {
        class: "bi bi-pen cursor-pointer",
        style: _normalizeStyle({
          color: $setup.variables[$props.evaluation.getEvaluation()?.toString() ?? 'null'].editColor,
          fontSize: '24px',
        }),
        "on:click": _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($props.onEdit && $props.onEdit(...args)))
      }, null, 36 /* STYLE, HYDRATE_EVENTS */),
      ($props.evaluation.getEvaluation() !== null)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(`bi bi-${$props.evaluation.getEvaluation() ? "check-circle" : "x-circle"}`),
            style: _normalizeStyle({
          color: $props.evaluation.getEvaluation() ? 'var(--token-semantic-color-success-default)' : 'var(--token-semantic-color-error-default)',
          fontSize: '24px',
        })
          }, null, 6 /* CLASS, STYLE */))
        : _createCommentVNode("v-if", true)
    ])
  ], 4 /* STYLE */))
}