import ClassificationModel from "./classification_model";

class EvaluationModel {

  private classification: ClassificationModel;
  private evaluation: boolean | null;

  constructor(params: {
    classification: ClassificationModel,
    evaluation: boolean | null,
  }) {
    this.classification = params.classification;
    this.evaluation = params.evaluation;
  }

  getClassification(): ClassificationModel {
    return this.classification;
  }

  getEvaluation(): boolean | null {
    return this.evaluation;
  }

}

export default EvaluationModel;