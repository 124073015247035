import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/close_cross.svg'


const _withScopeId = n => (_pushScopeId("data-v-c747386e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "common-modal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "blackout",
    style: _normalizeStyle({
    zIndex: $setup.zIndexRef
  }),
    ref: "blackout"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      ($props.canClose)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "close-btn",
            src: _imports_0,
            width: "15",
            height: "15",
            onClick: _cache[0] || (_cache[0] = () => {
          $setup.closeButton();
          $setup.props.onCloseByButton?.call(null);
        })
          }))
        : _createCommentVNode("v-if", true)
    ])
  ], 4 /* STYLE */))
}