import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c565346"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "answers-queue" }
const _hoisted_2 = {
  key: 1,
  class: "voting-prompt"
}
const _hoisted_3 = { class: "voting-prompt-options" }
const _hoisted_4 = { class: "voting-controllers" }
const _hoisted_5 = { class: "text-button-controller" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["ClassificationInfo"]),
    ($props.proposedAnswers.length >= 1)
      ? (_openBlock(), _createBlock($setup["AnswersPrompt"], {
          key: 0,
          "proposed-evaluation": $props.proposedAnswers[0],
          "on-click-help": $props.onClickHelp
        }, null, 8 /* PROPS */, ["proposed-evaluation", "on-click-help"]))
      : _createCommentVNode("v-if", true),
    ($props.proposedAnswers.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode($setup["CommonButton"], {
              action: "error",
              "on-click": (e) => $props.onEvaluate?.call(null, e, false)
            }, {
              default: _withCtx(() => [
                _createTextVNode("São produtos diferentes")
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["on-click"]),
            _createVNode($setup["CommonButton"], {
              action: "success",
              "on-click": (e) => $props.onEvaluate?.call(null, e, true)
            }, {
              default: _withCtx(() => [
                _createTextVNode("É o mesmo produto")
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["on-click"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("p", {
              class: "text-button-controller",
              "on:click": _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($props.onBack && $props.onBack(...args)))
            }, "< Voltar", 32 /* HYDRATE_EVENTS */),
            _createElementVNode("p", _hoisted_5, _toDisplayString($props.evaluatedAnswers.length) + "/" + _toDisplayString($props.proposedAnswers.length + $props.evaluatedAnswers.length), 1 /* TEXT */),
            _createElementVNode("p", {
              class: "text-button-controller",
              "on:click": _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($props.onJump && $props.onJump(...args)))
            }, "Pular >", 32 /* HYDRATE_EVENTS */)
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}