import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "common-button",
    onClick: _cache[0] || (_cache[0] = () => $props.onClick()),
    type: "button",
    style: _normalizeStyle($setup.cssVars)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(`content typography-${$setup.props.size}`)
    }, [
      ($props.icon)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(`bi ${$props.icon}`)
          }, null, 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2 /* CLASS */)
  ], 4 /* STYLE */))
}