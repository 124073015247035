import { type FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, getAuth, browserLocalPersistence, User  } from 'firebase/auth';
import CrawlerApiRequester from '../crawler_api/crawler_api_requester';
import UserStorage from '../user_storage/user_storage';

class FirebaseUtils {

  private static firebaseConfig = {
    apiKey: "AIzaSyAR7BsdjAkUhaDLuBVOXOh_Oq8mCYJv-gI",
    authDomain: "superopacrawler.firebaseapp.com",
    projectId: "superopacrawler",
    storageBucket: "superopacrawler.appspot.com",
    messagingSenderId: "630391455140",
    appId: "1:630391455140:web:9040a8064bc32e7ac5d531",
  };
  public static getFirebaseConfig() {
    return FirebaseUtils.firebaseConfig;
  }

  private static app: FirebaseApp;
  private static auth: Auth;

  static init(): FirebaseApp {
    FirebaseUtils.app = initializeApp(FirebaseUtils.firebaseConfig);
    FirebaseUtils.auth = getAuth(FirebaseUtils.app);
    this.auth.setPersistence(browserLocalPersistence);
    return FirebaseUtils.app;
  }

  static getApp() {
    if (!FirebaseUtils.app) {
      FirebaseUtils.init();
    }
    return FirebaseUtils.app;
  }

  static getCurrentUser(): Promise<User | null> {
    return new Promise((resolve, reject) => {
      const unsubscribe = this.auth.onAuthStateChanged(async user => {
        unsubscribe();
        resolve(user);
      }, reject);
    });
  }

  static getAuth() {
    if (!FirebaseUtils.auth) {
      FirebaseUtils.init();
    }
    return FirebaseUtils.auth;
  }

  static async logout() {
    await this.auth.signOut();
    CrawlerApiRequester.clearAxiosToken();
    UserStorage.clearUser();
  }

  static async hasTokenExpired(): Promise<boolean> {
    const firebaseUser = await FirebaseUtils.getCurrentUser();
    const tokenResult = await firebaseUser?.getIdTokenResult();
    
    let hasExpired = true;
    if (tokenResult?.expirationTime && new Date(tokenResult.expirationTime!).getTime() > Date.now()) {
      hasExpired = false;
    }
    return hasExpired;
  }

}

export default FirebaseUtils;