import ClassificationView from '@/views/ClassificationView.vue';
import LoginView from '@/views/LoginView.vue';
import RankingView from '@/views/RankingView.vue';
import HomeView from '@/views/HomeView.vue';
import ReportView from '@/views/ReportView.vue';
import { createRouter, createWebHashHistory } from 'vue-router';
import loginGuard from '@/nav_guards/login_guard';
import CrawlerView from '@/views/CrawlerView.vue';
import CrawlerAdvancedView from '@/views/CrawlerAdvancedView.vue';


export const routes = {
  login: {
    path: '/',
    name: 'login',
    component: LoginView,
  },
  home: {
    path: '/home',
    name: 'home',
    component: HomeView,
  },
  crawler: {
    path: '/crawler',
    name: 'crawler',
    component: CrawlerView,
  },
  ranking: {
    path: '/ranking',
    name: 'ranking',
    component: RankingView,
  },
  classification: {
    path: '/classification',
    name: 'classification',
    component: ClassificationView,
  },
  report: {
    path: '/reports',
    name: 'reports',
    component: ReportView,
  },
  crawlerAdvanced: {
    path: "/crawler-advanced/:id",
    name: "crawler-advanced",
    component: CrawlerAdvancedView,
  }
};

const router = createRouter({
  history: createWebHashHistory(),
  routes: Object.values(routes),
});

router.beforeEach(async (to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  if(!await loginGuard(to)) {
    return next({ path: routes.login.path });
  }
  next();
})

export default router;
