import WebScrappingInfra from "@/infra/crawler_api/web_scrapping_queue/web_scrapping_queue_infra";
import QueueItemModel from "@/models/queue_aggregate/queue_item_model";
import { IProductStudyData, IStudyData, StudyMethods } from "@/models/queue_aggregate/study_data";
import FormatUtils from "@/utils/format_utils";
import { defineStore } from "pinia";
import * as XLSX from "xlsx";

interface ICrawlerAdvancedState {
  queueItem: QueueItemModel | null,
  study: IStudyData | null,
  advancedProductCollapse: { index: number, isOpened: boolean, }[]
}

export const useCrawlerAdvancedStore = defineStore('crawlerAdvanced', {
  state: (): ICrawlerAdvancedState => ({
    queueItem: null,
    study: null,
    advancedProductCollapse: [],
  }),
  actions: {
    clear() {
      this.queueItem = null;
      this.study = null;
      this.advancedProductCollapse = [];
    },
    async getQueueItemById(id: string): Promise<QueueItemModel | null> {
      this.clear();
      this.queueItem = await WebScrappingInfra.getQueueItemById(id);
      this.study = this.queueItem?.getOutputData();
      if (this.queueItem === null) return null;
      this.advancedProductCollapse = this.getStudyData()?.products?.map((e, i) => ({
        index: i,
        isOpened: false,
      })) ?? [];
      return this.queueItem as QueueItemModel | null;
    },
    setIsOpen(index: number, v: boolean) {
      this.advancedProductCollapse.forEach(e => e.isOpened = false);
      this.advancedProductCollapse[index].isOpened = v;
    },
    getStudyData(): IStudyData | null {
      return this.study;
    },
    updateStudyData(lambda: (data: IStudyData) => void): void {
      const study = this.getStudyData();
      if (study) {
        lambda(study);
        study.alteredIn = Date.now() / 1000;
      }
      this.queueItem?.setOutputData(study);
    },
    updateStudyProductData(pos: number, data: IProductStudyData) {
      const study = this.getStudyData();
      if (study) {
        study.products[pos] = data;
        study.alteredIn = Date.now() / 1000;
      }
      this.queueItem?.setOutputData(study);
    },
    async saveStudyData() {
      if (!this.queueItem || !this.study) return;
      this.getStudyData()!.lastOpened = this.getStudyData()!.alteredIn;
      await WebScrappingInfra.saveQueueItemOutputData({
        queueItemId: this.queueItem.getId(),
        outputData: this.study,
      });
    },
    getProduct(index: number) {
      return this.study?.products[index];
    },
    generateSheetData(showFormatted=false): { [key: string]: string | number | null }[] {
      const info: any[] = [];
      const study = this.getStudyData();
      study?.products.forEach((e, index) => {
        const build = {};
        build['EAN'] = e.headerData?.ean;
        build['SKU'] = e.headerData?.sku;
        build['Nome'] = e.headerData?.name;
        build['Preço no APP'] = FormatUtils.money(e.headerData?.price, showFormatted);
        build['PMZ'] = FormatUtils.money(e.headerData?.priceCost, showFormatted);
        build['Preço unitário(última venda B2B)'] = FormatUtils.money(e.b2bData?.lastPriceSold, showFormatted);
        build['Quantidade vendida(última venda B2B)'] = FormatUtils.commonNumber(e.b2bData?.lastQuantitySold, 0);
        build['Preço médio vendido B2C(últimos 30 dias)'] = FormatUtils.money(e.b2cData?.averagePriceLast30Days, showFormatted);
        build['Quantidade vendida B2C(últimos 30 dias)'] = FormatUtils.commonNumber(e.b2cData?.amountSoldLast30Days, 0);
        build['Menor preço Crawler'] = FormatUtils.money(Math.min(...e.prices.map(e => e.price)), showFormatted);
        build['Preço médio Crawler'] = e.prices.length ? FormatUtils.money(e.prices.reduce((a, v) => a + v.price, 0) / e.prices.length, showFormatted) : '-';
        build['Maior preço Crawler'] = FormatUtils.money(Math.max(...e.prices.map(e => e.price)), showFormatted);
        build['Preço unitário da proposta'] = FormatUtils.money(e.inputsData.supplierPrice, true);
        build['Quantidade proposta'] = FormatUtils.commonNumber(e.inputsData.totalBatchSize, 2);
        build['Validade do lote'] = FormatUtils.date(e.inputsData?.batchExpiresAt * 1000);
        build['Margem Esperada'] = FormatUtils.commonNumber(e.inputsData.expectedMargin, 2);
        build['Preço Ideal APP'] = FormatUtils.money(StudyMethods.getAppIdealPrice(study, index), true);
        build['Dias até o vencimento'] = FormatUtils.commonNumber(StudyMethods.getDaysTillExpires(study, index), 0);
        build['Expectativa de perda'] = FormatUtils.commonNumber(StudyMethods.getLossExpectation(study, index), 0);
        build['Status'] = StudyMethods.getStatus(study, index);
        info.push(build);
      });
      return info;
    },
    async downloadStudyData() {
      const info = this.generateSheetData();
      const worksheet = XLSX.utils.json_to_sheet(info);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Dados');
      XLSX.writeFile(workbook, `${this.queueItem?.getTitle() ?? 'file.xlsx'}`, {
        bookType: 'xlsx',
        type: 'file',
      });
    },
    hasUnsavedChanges() {
      if (!this.getStudyData()) return false;
      return this.getStudyData()!.alteredIn > this.getStudyData()!.lastOpened;
    },
    removeProduct(product: IProductStudyData) {
      const study = this.getStudyData();
      if (!study) return;
      const index = study.products.indexOf(product);
      if (index < 0) return;
      study.products.splice(index, 1);
      this.advancedProductCollapse.splice(index, 1);
      this.queueItem?.setOutputData(study);
    },
  }
})