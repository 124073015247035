import UserModel from "@/models/user_aggregate/user_model";

import { JsonSerializer } from 'typescript-json-serializer';

class UserStorage {

  private static userKey = 'userInfo';

  static setUser(user: UserModel) {
    const jsonSerializer = new JsonSerializer;
    const userValue = JSON.stringify(jsonSerializer.serialize(user));
    if (!userValue) {
      return;
    }
    window.localStorage.setItem(UserStorage.userKey, userValue);
  }

  static clearUser() {
    window.localStorage.removeItem(UserStorage.userKey);
  }

  static getUser(): UserModel | null {
    const userStr = JSON.parse(window.localStorage.getItem(UserStorage.userKey) ?? 'null');
    const jsonSerializer = new JsonSerializer;
    const user = jsonSerializer.deserializeObject(userStr, UserModel);
    if (!user) return null;

    return user;
  }
}

export default UserStorage;