import Constants from "@/infra/constants/constants";
import CrawlerApiRequester from "../crawler_api_requester";
import { JsonSerializer } from "typescript-json-serializer";
import StoreModel from "@/models/store_model";
import QueueItemModel from "@/models/queue_aggregate/queue_item_model";
import { saveAs } from 'file-saver';
import { PaginatedListDTO } from "@/models/paginated_list_model";


class WebScrappingInfra {

  static async getStores() {
    try {
      const axios = CrawlerApiRequester.getAxios();
      const res = await axios.get(`${Constants.getCrawlerV2Endpoint()}/stores`);
      const jsonSerializer = new JsonSerializer();
      const stores = jsonSerializer.deserializeObjectArray(res.data, StoreModel) as StoreModel[];
      return stores;
    } catch(e) {
      console.log(e);
      return [];
    }
  }

  static async getQueueItems(params: {
    page: number,
    limit: number,
  }): Promise<PaginatedListDTO<QueueItemModel>> {
    try {
      const axios = CrawlerApiRequester.getAxios();
      const res = await axios.get(`${Constants.getCrawlerV2Endpoint()}/queue-items`, {
        params,
      });

      const jsonSerializer = new JsonSerializer();
      const queueItems = jsonSerializer.deserializeObjectArray(res.data.list, QueueItemModel) as QueueItemModel[];
      return new PaginatedListDTO<QueueItemModel>({
        list:queueItems, 
        pageCount: res.data.pageCount
      });
    } catch(e) {
      console.log(e);
      return PaginatedListDTO.empty<QueueItemModel>();
    }
  }

  static async getQueueItemById(id: string): Promise<QueueItemModel | null> {
    try {
      const axios = CrawlerApiRequester.getAxios();
      const res = await axios.get(`${Constants.getCrawlerV2Endpoint()}/queue-items/${id}`);

      const jsonSerializer = new JsonSerializer();
      const queueItem = jsonSerializer.deserializeObject(res.data, QueueItemModel) as QueueItemModel;
      return queueItem;
    } catch(e) {
      console.log(e);
      return null;
    }
  }

  static async downloadQueueItemFile(queueItemId: string, params: {
    sheet_title: string,
  }): Promise<Blob | null> {
    try {
      const axios = CrawlerApiRequester.getAxios();
      const res = await axios.get(`${Constants.getCrawlerV2Endpoint()}/queue-items/${queueItemId}/download`, {
        params,
        responseType: "blob",
      });
      saveAs(res.data, params.sheet_title);
      return res.data;
    } catch(e) {
      console.log(e);
      return null;
    }
  }

  static async saveQueueItemOutputData(params: { queueItemId: string, outputData: any}): Promise<boolean> {
    try {
      const axios = CrawlerApiRequester.getAxios();
      await axios.patch(`${Constants.getCrawlerV2Endpoint()}/queue-items/${params.queueItemId}/output-data`, {
        ...params.outputData,
      });
      return true;
    } catch(e) {
      console.log(e);
      return false;
    }
  }

  static async enqueue(params: {
    process_info: {
      title: string,
      input_connector_id: string,
      input_params: object,
      output_connector_id: string,
      ling_comp_connector_id: string,
      stores_to_run?: string[] | null,
      tags_to_run?: string[] | null,
    },
    files: {
      [key: string]: Blob,
    }
  }): Promise<boolean> {
    try {
      const axios = CrawlerApiRequester.getAxios();
      const formData = new FormData();
      formData.append("process_info", JSON.stringify(params.process_info));
      for (const key in params.files) {
        formData.append("files", params.files[key], key);
      }
      await axios.post(`${Constants.getCrawlerV2Endpoint()}/queue-items/enqueue`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return true;
    } catch(e) {
      console.log(e);
      return false;
    }
  }

  static async stopQueueItem(queueItemId: string | undefined): Promise<boolean> {
    try {
      const axios = CrawlerApiRequester.getAxios();
      await axios.post(`${Constants.getCrawlerV2Endpoint()}/queue-items/${queueItemId}/stop`);
      return true;
    } catch(e) {
      console.log(e);
      return false;
    }
  }
  
}

export default WebScrappingInfra;