import { createApp } from 'vue';
import { createPinia } from 'pinia';

import App from './App.vue';
import router from './router';
import DarkMode from './infra/dark_mode/dark_mode';
import FirebaseUtils from './infra/firebase/firebase_utils';

DarkMode.updateTheme();

import './assets/base.css';

FirebaseUtils.init();

const app = createApp(App);

app.use(createPinia());
app.use(router);

app.mount('#app');
