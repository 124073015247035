import UserStorage from '@/infra/user_storage/user_storage';
import FirebaseUtils from '@/infra/firebase/firebase_utils';
import { type UserCredential, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { defineStore } from 'pinia';
import CrawlerApiRequester from '@/infra/crawler_api/crawler_api_requester';
import CrawlerUserInfra from '@/infra/crawler_api/crawler_user/crawler_user_infra';

export const useLoginStore = defineStore('login', {
  state: () => ({}),
  actions: {
    async loginWithGoogle(): Promise<string | null> {
      try {
        const auth = FirebaseUtils.getAuth();
        const result: UserCredential = await signInWithPopup(auth, new GoogleAuthProvider());
        const token = await result.user.getIdToken();
        if (!token) return 'Erro de token';
        CrawlerApiRequester.setAxiosToken(token);
        const crawlerUser = await CrawlerUserInfra.singIn();
        if (!crawlerUser) return 'Erro de usuário';

        UserStorage.setUser(crawlerUser);
        return null;
        
      } catch (e: any) {
        console.log(e);
        return 'Erro desconhecido';
      }
    }
  },
});