import { JsonObject, JsonProperty } from "typescript-json-serializer";

@JsonObject()
class StoreModel {

  @JsonProperty()
  name: string;

  @JsonProperty()
  label: string;
  
  @JsonProperty()
  tags: string[];

  @JsonProperty("uses_ean")
  usesEan: boolean;

  constructor(
    name: string,
    label: string,
    tags: string[],
    uses_ean: boolean,
  ) {
    this.name = name;
    this.label = label;
    this.tags = tags;
    this.usesEan = uses_ean;
  }

  public getName() {
    return this.name;
  }
}

export default StoreModel;