import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e68c989"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "gamification-modal" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  ref: "titleElem",
  class: "title-small centralized-elem"
}
const _hoisted_4 = {
  ref: "contentElem",
  class: "centralized-elem"
}
const _hoisted_5 = {
  class: "continue-options",
  ref: "continueOptions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["CommonModal"], {
    ref: "gamificationModal",
    "on-close-by-button": () => $setup.giveUp ? $setup.callback(null) : $setup.open(null)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          ref: "img",
          alt: "Gamification emoji",
          src: require('@/assets/images/fire.png'),
          class: "gamification-emoji"
        }, null, 8 /* PROPS */, _hoisted_2),
        _createElementVNode("h3", _hoisted_3, null, 512 /* NEED_PATCH */),
        _createElementVNode("p", _hoisted_4, null, 512 /* NEED_PATCH */),
        _createElementVNode("div", _hoisted_5, [
          _createVNode($setup["CommonButton"], {
            class: "btn btn-primary",
            "on-click": () => $setup.callback(20)
          }, {
            default: _withCtx(() => [
              _createTextVNode("Bora de mais +20")
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["on-click"]),
          _createVNode($setup["CommonButton"], {
            class: "btn btn-primary",
            "on-click": () => $setup.callback(50)
          }, {
            default: _withCtx(() => [
              _createTextVNode("Vou destruir +50")
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["on-click"])
        ], 512 /* NEED_PATCH */),
        (!$setup.giveUp)
          ? (_openBlock(), _createBlock($setup["CommonButton"], {
              key: 0,
              type: "text",
              class: "btn btn-primary",
              onClick: _cache[0] || (_cache[0] = () => {
        $setup.open(null);
      })
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Finalizar por hoje, amanhã tem mais! ")
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        ($setup.giveUp)
          ? (_openBlock(), _createBlock($setup["CommonButton"], {
              key: 1,
              type: "text",
              class: "btn btn-primary",
              onClick: _cache[1] || (_cache[1] = () => $setup.callback(null))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Voltar à tela de classificação ")
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["on-close-by-button"]))
}