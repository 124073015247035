class Constants {
  static project = "superopacrawler";
  static stage = (window.localStorage.getItem("STAGE") as "prod" | "dev") ?? 'dev';

  static getCrawlerAPIEndpoint() {
    switch (Constants.stage) {
      case "prod":
        return "https://tf-prod-crawler-superopa-api-353j6bqugq-rj.a.run.app";
      default:
        return "https://tf-dev-crawler-superopa-api-353j6bqugq-rj.a.run.app";
    }
  }

  static getCrawlerV2Endpoint() {
    switch (Constants.stage) {
      case "prod":
        return "https://prod-crawler-v2-service-dot-superopacrawler.rj.r.appspot.com";
      default:
        return "https://dev-crawler-v2-service-dot-superopacrawler.rj.r.appspot.com";
    }
  }

} export default Constants;