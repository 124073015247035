import { JsonObject, JsonProperty } from "typescript-json-serializer";

@JsonObject()
class StoreQueueInfoModel {

  @JsonProperty()
  private id: string;

  @JsonProperty("queue_item_id")
  private queueItemId: string;

  @JsonProperty("end_date_utc")
  private endDateUtc: number;

  @JsonProperty("found_items")
  private foundItems: number;

  @JsonProperty("not_found_items")
  private notFoundItems: number;

  @JsonProperty("store_name")
  private storeName: string;

  @JsonProperty("total_items")
  private totalItems: number;

  constructor(params: {
    id: string;
    queueItemId: string;
    endDateUtc: number;
    foundItems: number;
    notFoundItems: number;
    storeName: string;
    totalItems: number;
  }) {
    this.id = params.id;
    this.queueItemId = params.queueItemId;
    this.endDateUtc = params.endDateUtc;
    this.foundItems = params.foundItems;
    this.notFoundItems = params.notFoundItems;
    this.storeName = params.storeName;
    this.totalItems = params.totalItems;
  }

  public getId() {
    return this.id;
  }

  public getTotalItems() {
    return this.totalItems;
  }

  public getStoreName() {
    return this.storeName;
  }

  public getNotFoundItems() {
    return this.notFoundItems;
  }

  public getFoundItems() {
    return this.foundItems;
  }

  public getRunPercentage() {
    return (this.foundItems + this.notFoundItems) / this.totalItems;
  }

  public getAccuracyPercentage() {
    return this.foundItems / (this.totalItems);
  }

  public getEstimatedTime() {
    return this.getRunPercentage() * this.getAccuracyPercentage();
  }
}

export default StoreQueueInfoModel;