import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77d5a93c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "answers-form-header" }
const _hoisted_2 = { class: "answers-form-title" }
const _hoisted_3 = { class: "answers-form-content" }
const _hoisted_4 = { class: "answers-form-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "answers-form",
    style: _normalizeStyle($setup.style)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, "Respostas (" + _toDisplayString($props.answers.length) + ")", 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.answers, (answer) => {
        return (_openBlock(), _createBlock($setup["ComparisonEvaluation"], {
          key: answer.getClassification().getId(),
          evaluation: answer,
          "on-edit": () => $props.onEdit(answer)
        }, null, 8 /* PROPS */, ["evaluation", "on-edit"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode($setup["CommonButton"], { "on-click": $props.onSendAnswers }, {
        default: _withCtx(() => [
          _createTextVNode("Enviar respostas")
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["on-click"])
    ])
  ], 4 /* STYLE */))
}