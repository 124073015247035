import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-164324a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "common-switch-father" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "bold-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (option) => {
      return (_openBlock(), _createElementBlock("div", {
        key: option,
        class: _normalizeClass(`common-switch-content ${option == $props.currentSelected ? 'enabled' : 'disabled'}`),
        onClick: () => $props.onChange(option)
      }, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(option), 1 /* TEXT */)
      ], 10 /* CLASS, PROPS */, _hoisted_2))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}