import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2656244c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-3 create-edit-father" }
const _hoisted_2 = { class: "title-small centralized-elem text-center p-3" }
const _hoisted_3 = { class: "d-flex flex-column gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["CommonModal"], {
    ref: "commonModal",
    "on-close-by-button": $props.onCancel
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString($setup.reportModel ? 'Editar' : 'Criar') + " relatório", 1 /* TEXT */),
        _createVNode($setup["CommonInput"], {
          label: "Titulo",
          modelValue: $setup.title,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.title) = $event)),
          onTapTrailing: _cache[1] || (_cache[1] = ($event: any) => ($setup.title = ''))
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createVNode($setup["CommonInput"], {
          label: "Descrição",
          modelValue: $setup.description,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.description) = $event)),
          onTapTrailing: _cache[3] || (_cache[3] = ($event: any) => ($setup.description = ''))
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createVNode($setup["CommonInput"], {
          label: "URL",
          modelValue: $setup.url,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.url) = $event)),
          onTapTrailing: _cache[5] || (_cache[5] = ($event: any) => ($setup.url = ''))
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createVNode($setup["CommonInput"], {
          label: "Tags",
          modelValue: $setup.tags,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.tags) = $event)),
          onTapTrailing: _cache[7] || (_cache[7] = ($event: any) => ($setup.tags = ''))
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createVNode($setup["CommonInput"], {
          label: "Largura",
          modelValue: $setup.size[0],
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.size[0]) = $event)),
          onTapTrailing: _cache[9] || (_cache[9] = ($event: any) => ($setup.size[0] = ''))
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createVNode($setup["CommonInput"], {
          label: "Altura",
          modelValue: $setup.size[1],
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($setup.size[1]) = $event)),
          onTapTrailing: _cache[11] || (_cache[11] = ($event: any) => ($setup.size[1] = ''))
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode($setup["CommonButton"], { "on-click": $setup.createEdit }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.reportModel ? 'Editar' : 'Criar'), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode($setup["CommonButton"], {
            action: "error",
            "on-click": () => {
          $setup.commonModal?.close();
          $props.onCancel?.call(null);
        }
          }, {
            default: _withCtx(() => [
              _createTextVNode("Cancelar")
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["on-click"])
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["on-close-by-button"]))
}