import Constants from '../constants/constants';
import { Firestore, getFirestore, collection } from "firebase/firestore";
import FirebaseUtils from '../firebase/firebase_utils';

class FirestoreDS {

  private static db: Firestore | null = null;
  public static getDB() {
    if (this.db == null) {
      this.db = getFirestore(FirebaseUtils.getApp());
    }
    return this.db;
  }

  public static getCollection(collectionName: string) {
    const db = this.getDB();
    return collection(db, Constants.project, Constants.stage, collectionName);
  }

}

export default FirestoreDS;