import { JsonObject, JsonProperty } from "typescript-json-serializer";
import StoreQueueInfoModel from "./store_queue_info_model";

@JsonObject()
class StoresInfoModel {

  @JsonProperty("id")
  private queueItemId: string;

  @JsonProperty("stores_info")
  private storesInfo: Record<string, StoreQueueInfoModel>;

  constructor(params: {
    queueItemId: string;
    storesInfo: Record<string, StoreQueueInfoModel>
  }) {
    this.queueItemId = params.queueItemId;
    this.storesInfo = params.storesInfo;
  }

  public getId() {
    return this.queueItemId;
  }

  public getStoresInfo() {
    return this.storesInfo;
  }

  public getTotalPercent() {
    let totalPercent = 0;
    for (const storeName in this.storesInfo) {
      totalPercent += this.storesInfo[storeName].getRunPercentage();
    }
    const percent = totalPercent / Object.keys(this.storesInfo).length;
    if (isNaN(percent)) return 0;
    return percent * 100;
  }

}

export default StoresInfoModel;