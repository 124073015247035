import { JsonSerializer } from "typescript-json-serializer";
import FirestoreDS from "../firestore_ds";
import StoresInfoModel from "@/models/queue_aggregate/stores_info_model";
import { doc, onSnapshot } from "firebase/firestore";
import StoreQueueInfoModel from "@/models/queue_aggregate/store_queue_info_model";

class StoreQueueInfoFirestore {

  static collection = 'stores_data';

  static async onQueueUpdate(documentId: string, onSnapshotCallback: (snapshot: StoresInfoModel | null) => void) {
    try {
      const storeQueueInfo = FirestoreDS.getCollection(this.collection);
      const docRef = doc(storeQueueInfo, documentId);
      onSnapshot(docRef, (doc) => {
        const data = doc.data();
        if (!data) return onSnapshotCallback(null);

        const serializer = new JsonSerializer();
        const storesInfo = new StoresInfoModel({
          queueItemId: data.id,
          storesInfo: Object.values(data.stores_info).reduce((acc: Record<string, StoreQueueInfoModel>, storeInfo: any) => {
            const obj = serializer.deserializeObject(storeInfo, StoreQueueInfoModel);
            if (obj) acc[obj.getStoreName()] = obj;
            return acc;
          }, {} as Record<string, StoreQueueInfoModel>)
        });

        if (!storesInfo) return onSnapshotCallback(null);
        onSnapshotCallback(storesInfo);
      });
    } catch(e) {
      console.log("Error onQueueUpdate: ", e);
      return null;
    }
  }

}

export default StoreQueueInfoFirestore;